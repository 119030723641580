import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    // padding: "70px 0 0",
  },
  footerSection1: {
    background: theme.palette.footer.main,
    minHeight: "150px",
    padding: "2rem 3rem 0 3rem",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem !important",
      paddingBottom: "10px !important",
    },
  },
  footerSection2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "65px",
    padding: "0 40px",
    background: theme.palette.footerCard.main,
    "@media (max-width: 1330px)": {
      minHeight: "50px",
    },
    "@media (max-width: 500px)": {
      display: "block",
      paddingTop: '10px',
    },
  },

  footerPowered: {
    textDecoration: "underline"
  },
  footerText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "27px",
    textAlign: "center",
    // textTransform: "uppercase",
    color: theme?.palette?.footerTextColor?.main || 'white',
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      fontSize: "10px !important",
    },
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
  },
  logo: {
    height: "auto",
    filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))",
  },
  logoGrid: {
    width: "54%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
    },
  },
  header_text_1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "29px",
    lineHeight: "38px",
    color: "#00CB74",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  header_text_2: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "29px",
    lineHeight: "38px",
    color: "#00CB74",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  anchor: {
    "&:hover": {
      color: "red",
    },
  },
  header_sub_text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "rgba(255, 255, 255, 0.8)",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  link: {
    color: theme?.palette?.footerTextColor?.main || 'white',
    fontSize: "0.9rem",
    "&:hover": {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  listPage: {
    paddingTop: "4px",
    paddingBottom: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "27px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    "@global": {
      span: {
        fontFamily: "Poppins",
      },
    },
  },
  footerNavTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme?.palette?.footerTextColor?.main || 'white',
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  title1: {
    // textTransform: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "27px",
    color: theme?.palette?.footerTextColor?.main || 'white',
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  description: {
    display: "flex",
    marginTop: "0px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "25px",
    },
  },
  input: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      color: "white",
      borderRadius: "4px",
      outline: "none",
      background: theme.palette.footer.main,
      "& fieldset": {
        borderColor: "#9C9C9C",
      },
      "&:hover fieldset": {
        borderColor: "#9C9C9C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9C9C9C",
      },
    },
  },
  email: {
    position: "absolute",
    padding: "10px",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    gap: "14px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      gap: "9px",
    },
  },
  followSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginRight: "0px",
    border: "2px solid " + theme?.palette?.footerTextColor?.main,
    width: "35px",
    height: "35px",
    borderRadius: "100%",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "36px",
      width: "36px",
    },
  },
  followLogo: {
    position: "absolute",
    height: "19px",
    width: "19px",
    left: "8px",
  },
  facebook: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: `#3B5998`,
      color: "white"
    },
  },
  youtube: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: `#DD4B39`,
      color: "white"
    },
  },
  instagram: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: "rgb(231,143,96)",
      background:
        "linear-gradient(0deg, rgba(231,143,96,1) 37%, rgba(213,95,95,1) 49%, rgba(175,64,120,1) 63%)",
      color: "white"
    },
  },
  twitter: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: "#14171A",
      color: "white"
    },
  },
  linkedin: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: "#0077B5",
      color: "white"
    },
  },
  pinterest: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: "#c8232c",
      color: "white"
    },
  },
  snapchat: {
    color: theme?.palette?.footerTextColor?.main,
    "&:hover": {
      background: "#ffc621",
      color: "white"
    },
  },
}));

export default useStyles;
