/*
 *
 *  products remotes
 *
 */

import { decryption, encryption } from "cipher/cipher";
import api from "../../helpers/api";
import { createQueryString } from "utils/tools";

/**
 *
 * @param {string} mainUrl
 * @param {object} geoLocation
 * @returns
 */
function commomFun(mainUrl, geoLocation) {
  if (geoLocation) {
    const coords = geoLocation?.coordinates;
    let queryParams = "?";
    if (coords?.lat || coords?.lng || geoLocation?.kiloMeter) {
      // Include parameters if any of the conditions are true
      if (coords?.lat) {
        queryParams += `latitude=${coords.lat}&`;
      }
      if (coords?.lng) {
        queryParams += `longitude=${coords.lng}&`;
      }
      if (geoLocation?.kiloMeter) {
        queryParams += `radius=${geoLocation.kiloMeter}&`;
      }
    }

    return `${mainUrl}${queryParams}`;
  } else {
    return `${mainUrl}?`;
  }
}

function queryStringFunc(commonUrl, queryString) {
  let urlString;
  if (queryString) {
    return (urlString = `${commonUrl}&${queryString}`);
  } else {
    return (urlString = `${commonUrl}`);
  }
}

/**
 *
 * @param {object} headers
 * @param {object} geoLocation
 * @param {boolean} loggedIn
 * @param {boolean} productFilter
 * @returns
 */
export async function loadProducts(
  headers,
  geoLocation = {},
  loggedIn,
  productFilter,
  query,
) {
  let mainUrl = loggedIn ? `/rest/posts` : `/rest/public-posts`;
  let url = commomFun(mainUrl, geoLocation);

  return await api
    .get(
      `${url}&offset=${headers.offset}&limit=${headers.limit}&filter=${headers?.filter || ""
      }&product_filter=${query || productFilter}`,
    )
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      response.data = decryptData;
      console.log('it was called ', response.data);

      return response.data
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @param {object} geoLocation
 * @param {boolean} loggedIn
 * @returns
 */
export async function loadProduct(record, geoLocation, loggedIn) {
  let mainUrl = loggedIn ? `posts/${record}` : `/rest/public-posts/${record}`;
  let url = commomFun(mainUrl, geoLocation);
  return await api
    .get(`${url}`)
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function addWishlistProduct(record) {
  return await api
    .post(`rest/wishlist`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @param {object} headers
 * @returns
 */
export async function getAllWishlistProducts(record, headers) {

  return await api
    .get(
      `rest/wishlist/${record.id}?&offset=${headers.offset}&limit=${headers.limit}`,
    )
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      response.data = decryptData;
      return response.data;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function deleteWishlistProduct(record) {
  return await api
    .delete(`rest/wishlist/${record.id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function createUserComment(record) {
  let encryptData = await encryption(record);
  return await api
    .post(`rest/comments`, { data: encryptData })
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function createProductLike(record) {
  return await api
    .post(`rest/like`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function createProductUnLike(record) {
  return await api
    .post(`rest/unlike`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function deleteUserComment(record) {
  return await api
    .delete(`rest/comments/${record.id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
export async function editUserComment(record) {
  return await api
    .put(`rest/comments/${record.id}`, record)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} geoLocation
 * @param {object} loggedIn
 * @returns
 */
export async function loadBanner(geoLocation, loggedIn) {
  let mainUrl = loggedIn ? `/rest/banner` : `/rest/public-banner`;
  let url = commomFun(mainUrl, geoLocation);
  return await api
    .get(`${url}&sort_type=asc`)
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} search
 * @param {object} geoLocation
 * @param {boolean} loggedIn
 * @returns
 */
export async function searchProducts(
  queryParams,
  loggedIn,
  geoLocation = {},
  headers,
) {
  let mainUrl = loggedIn ? `/rest/posts` : `/rest/public-posts`;
  let url = commomFun(mainUrl, geoLocation);

  let queryString =
    queryParams &&
    Object.keys(queryParams).length > 0 &&
    Object.assign({}, queryParams, { search: queryParams.query });
  delete queryString.query;
  queryString = queryString && createQueryString(queryString);

  const commonUrl = `${url}&offset=${headers.offset}&limit=${headers.limit
    }&filter=${headers.filter || "most_recent"}`;
  const finalUrl = queryStringFunc(commonUrl, queryString);

  const records = await api
    .get(`${finalUrl}`)
    .then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData
    })
    .catch((error) => Promise.reject(error));

  const filterResponse = queryString && queryString.indexOf('free_delivery') > -1 && records && records?.response.length > 0 ? records?.response.filter(_ => ((_.new_price &&
    _.old_price &&
    parseFloat(_.new_price) !== 0 &&
    parseFloat(_.old_price) !== 0) ||
    (_.old_price && parseFloat(_.old_price) !== 0) ||
    (_.new_price && parseFloat(_.new_price) !== 0))) : [];
  return filterResponse && filterResponse.length > 0 ? Object.assign({}, records, { response: filterResponse }) : records;
}

export async function clickTracking(record) {
  return await api
    .post(`/rest/click-tracking`, record)
    .then((response) => response && response.data)
    .catch((error) => Promise.reject(error));
}

export async function shareTracking(record) {
  return await api
    .post(`/rest/share-tracking`, record)
    .then((response) => response && response.data)
    .catch((error) => Promise.reject(error));
}

export async function postSecretKey(record) {
  return await api
    .post(`/rest/secret/post-categories`, record)
    .then((response) => response && response.data)
    .catch((error) => Promise.reject(error));
}

const remotes = {
  loadProducts,
  loadProduct,
  addWishlistProduct,
  getAllWishlistProducts,
  deleteWishlistProduct,
  searchProducts,
  createUserComment,
  createProductLike,
  createProductUnLike,
  deleteUserComment,
  editUserComment,
  loadBanner,
  clickTracking,
  shareTracking,
  postSecretKey,
};

export default remotes;
