/**
 *
 *
 * Mobile Component
 *
 *
 */
import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import Styles from "./styles";
// import headerLogo from "assets/images/logoWithText1.svg";
import locationIcon from "assets/CommonIcons/marker.png";
import account from "assets/images/header/account1.svg";
import DrawerComponent from "../Drawer";
import { capitalizeFirstLetter, checkString } from "utils/tools";
import CustomSearchField from "components/CustomSearchField";
import personLogo from "assets/images/card/profile.png";
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from "react";
import api from "helpers/api";
import { useSSR } from "react-i18next";
import { useState } from "react";

function MobileView(props) {
  const classes = Styles();
  const {
    loggedIn,
    handleModalFunc,
    handleLogout,
    user,
    geoLocation,
    setShowMap,
    handleSearchFunc,
    suggestionOptions,
    setValue,
    value,
    handleReloadPage,
    handleClear,
    dispatch,
    categories
  } = props;
  const userName = user?.name && capitalizeFirstLetter(user?.name);
  const profileLogo = user?.profile_image || personLogo;

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    async function getLogo() {
      try {
        let response = await api.get('/rest/accounts/get/logo');
        setLogo(response.data.value)
      }
      catch (err) {
        console.log(err);
      }
    }
    getLogo()
  }, [])

  return (
    <AppBar
      id="header"
      className={classes.appBar}
      color="headerPrimary"
      position="fixed"
    >
      <Toolbar className={classes.headerSection} sx={{ gap: "2px" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "2px",
          }}
        >
          <Grid container justifyContent="flex-start">
            <a
              href={
                process.env.NODE_ENV === "development"
                  ? "/"
                  : global.config.APP_URL
              }
              onClick={handleReloadPage}
            >
              <img
                src={logo}
                alt="header_logo"
                style={{ width: "auto", height: "45px" }}
              />
            </a>
          </Grid>
          {!loggedIn && (
            <Grid
              container
              justifyContent="flex-end"
              className={`${classes.accountContainer} ${classes.headerRows}`}
              sx={{ gap: 1 }}
            >
              <Tooltip title="Sign in" placement="top">
                <img
                  src={account}
                  alt="account"
                  onClick={() => handleModalFunc("login")}
                  style={{ cursor: "pointer", marginBottom: "5px" }}
                />
              </Tooltip>
            </Grid>
          )}

          {loggedIn && (
            <Grid
              container
              justifyContent="flex-end"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.userName}>
                {(userName &&
                  userName.length > 11 &&
                  `${userName.substring(0, 11)}...`) ||
                  userName}
              </Typography>
              <DrawerComponent
                user={user}
                handleLogout={handleLogout}
                dispatch={dispatch}
              >
                {(open) => {
                  return (
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={open()}
                      color="inherit"
                      style={{ paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px" }}
                    >
                      <img src={profileLogo} className={classes.profileImg} alt="profile logo" />
                    </IconButton>
                  );
                }}
              </DrawerComponent>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.searchContainer} ${classes.headerRows}`}
        >
          <CustomSearchField
            type="text"
            placeholder={"Explore deals: Search now"}
            className={classes.searchBar}
            handleChange={handleSearchFunc}
            fullWidth
            options={suggestionOptions}
            setValue={setValue}
            handleClear={handleClear}
            value={value}
            categories={categories}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.location}
          onClick={() => setShowMap(true)}
        >
          <img
            className={classes.header_location}
            src={locationIcon}
            alt="location"
          />
          <Grid item className={classes.headerLocationText}>
            {!geoLocation?.locationName && !geoLocation?.kiloMeter ? (
              <>
                <span>
                  Hello &nbsp;
                </span>
                <span style={{ color: "#656262" }}><b style={{ fontWeight: "normal" }}>choose your location for nearby deals</b></span>
              </>
            ) : (
              <span>
                {geoLocation?.kiloMeter
                  ? `Near me - ${geoLocation?.kiloMeter}km`
                  : null}{" "}
                {(geoLocation?.locationName &&
                  checkString(geoLocation?.locationName, 45, true)) ||
                  null}
              </span>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default MobileView;
